import {Component, Input, OnInit} from '@angular/core';
import {ZenColors} from '../../_enums/zen-colors.enum';
import {NgxSkeletonLoaderModule} from "ngx-skeleton-loader";
import {CommonModule} from "@angular/common";

export enum ZenLoaderTypes {
  CHART = 'CHART',
  SNAP_SHOT = 'SNAP_SHOT',
  TABS = 'TABS',
  TABLE = 'TABLE',
  USAGE = 'USAGE',
  TABLE_BG = 'TABLE_BG'
}

const barStyle = {
  'background-color': ZenColors.textColorLight_3,
  height: '0.8rem',
  'border-radius': '1rem',
  'margin-bottom': '0.1rem'
};

@Component({
  selector: 'app-skeleton-loader',
  templateUrl: './skeleton-loader.component.html',
  standalone: true,
  imports: [NgxSkeletonLoaderModule, CommonModule],
  styleUrls: ['./skeleton-loader.component.scss']
})
export class SkeletonLoaderComponent implements OnInit {
  @Input() loaderType: ZenLoaderTypes;
  ZenLoaderTypes = ZenLoaderTypes;

  // #EFF5F8 and #F9FDFF
  theme = {
    ...barStyle,
    width: '100%'
  };

  usageBar = {
    ...barStyle,
    width: '100%',
    height: '4rem'
  };

  chartSmallBar = {
    ...barStyle,
    'background-color': ZenColors.textColorLight_3,
    height: '1rem',
    width: '75%',
    'margin-bottom': '1rem'
  };

  chartCircle = {
    'background-color': ZenColors.textColorLight_3,
    height: '8rem',
    width: '8rem'
  };

  snapShotCircle = {
    'background-color': ZenColors.textColorLight_3
  }
  snapShotBar = {
    'background-color': ZenColors.textColorLight_3,
    height: '1.75em',
    'border-radius': '0.25rem',
    'margin-bottom': '0.1rem'
  };

  inputBar = {
    'background-color': ZenColors.textColorLight_3,
    height: '4em',
    width: '12rem',
    'border-radius': '0.25rem'
  };

  tableContentSec = {
    'background-color': ZenColors.textColorLight_3,
    height: '30em',
    width: '100%',
    'border-radius': '0.5rem',
    'margin-bottom': '0.1rem'
  };

  tableRow = {
    'background-color': ZenColors.textColorLight_3,
    height: '2rem',
    width: '100%',
    'border-radius': '1rem',
    'margin-bottom': '1rem'
  };

  constructor() {
  }

  ngOnInit(): void {
  }

}
