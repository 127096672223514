import {Component, Inject} from '@angular/core';
import {DialogWrapperComponent} from "../dialog-wrapper/dialog-wrapper.component";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {DialogActionButton, DialogDataModel} from "../../_model/dialog.model";

@Component({
    selector: 'app-default-dialog',
    standalone: true,
    imports: [
        DialogWrapperComponent
    ],
    templateUrl: './default-dialog.component.html',
    styleUrl: './default-dialog.component.scss'
})
export class DefaultDialogComponent {

    constructor(@Inject(MAT_DIALOG_DATA) public data: DialogDataModel,
                public dialogRef: MatDialogRef<DefaultDialogComponent>) {
    }

    submit(btn: DialogActionButton) {
        btn.command({});
    }

}
