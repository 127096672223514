import {ButtonTypes} from "./button.model";

export interface TableMenuOption {
    children?: TableMenuOption[];
    divider?: boolean;
    label: string;
    beta?: boolean;
    type?: 'button' | 'toggle';
    value?: string;
    icon?: string;
    iconPosition?: 'right' | 'left';
    command?: Function;
    toggleDefaultVal?: boolean;
    field?: string;

    // Used in the portfolio details page
    hideForDesktop?: boolean;
    hide?: boolean;
    disabled?: boolean;
}

export enum ButtonActionTypes {
    SUBMIT = 'SUBMIT', CANCEL = 'CANCEL'
}

export interface DialogActionButton {
    label: string;
    btnType?: ButtonTypes;
    color?: 'primary' | 'secondary' | 'warn';
    command?: ({}) => void;
    actionType?: ButtonActionTypes;
    styleClass?: string;
    prefixIcon?: string;
    suffixIcon?: string;
    menuItems?: TableMenuOption[];
    showLoader?: boolean;
}

export interface DialogDataModel {
    data?: any;
    header?: {
        title: string; titleCls?: string; icon?: string; iconClass?: string;
        popperDet?: { title?: string; message: string };
    };
    onClose?: () => void;
    hideCloseX?: boolean;
    bodyText?: string;
    bodyHtml?: string;
    deleteConfirmation?: {
        deleteHeader: string;
        deleteBody: string,
        deleteClicked?: boolean,
        deleteCommand?: () => void
    };
    actionButtons?: DialogActionButton[];
}
