import {Component, OnDestroy} from '@angular/core';
import {Subject} from "rxjs";
import {NgxPopperjsPlacements, NgxPopperjsTriggers} from "ngx-popperjs";
import {CommodityType, CreditStatus} from "../../_model/deal-desk.model";
import {ZenIconsEnum} from "../../_enums/zen-icons.enum";
import {ZenLoaderTypes} from "../skeleton-loader/skeleton-loader.component";
import {NumberFormat} from "../../_enums/number-format.enum";

@Component({
    selector: 'app-base',
    standalone: true,
    imports: [],
    templateUrl: './base.component.html',
    styleUrl: './base.component.scss'
})
export class BaseComponent implements OnDestroy {
    NgxPopperjsTriggers = NgxPopperjsTriggers;
    NgxPopperjsPlacements = NgxPopperjsPlacements;
    NumberFormat = NumberFormat;
    ZenIconsEnum = ZenIconsEnum;
    CreditStatus = CreditStatus;
    ZenLoaderTypes = ZenLoaderTypes;
    CommodityType = CommodityType;

    public readonly destroy$ = new Subject<void>();

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }
}
