import {Injectable} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ZenIconsEnum} from '../../_enums/zen-icons.enum';
import {ToastComponent, ZenToastDataModel} from "../../_standalone-components/toast/toast.component";
import {ButtonActionTypes, DialogDataModel} from "../../_model/dialog.model";
import {ButtonTypes} from "../../_model/button.model";
import {DialogSizeEnum} from "../../_enums/dialog.enum";
import {MatDialog} from "@angular/material/dialog";
import {DefaultDialogComponent} from "../../_standalone-components/default-dialog/default-dialog.component";

@Injectable({
    providedIn: 'root'
})
export class ZenDialogMsgService {

    constructor(
        private snackBar: MatSnackBar,
        private dialog: MatDialog
    ) {
    }

    openToast(success: boolean, msg?: string, duration?: number) {
        const cls = success ? 'success-toast' : 'warn-toast';
        this.snackBar.openFromComponent(ToastComponent, {
            duration: duration || 6000,
            horizontalPosition: 'end',
            verticalPosition: 'top',
            panelClass: [cls],
            data: {
                message: msg || (success ? 'Success' : 'Error'),
                icon: success ? ZenIconsEnum.DONE : ZenIconsEnum.ERROR
            } as ZenToastDataModel
        });
    }

    showConfirmationDialog(title: string, bodyText: string, onCancel: Function, onConfirm: Function, cancelText?: string, confirmText?: string, afterClosed?: Function, onClose?: Function, width?: DialogSizeEnum) {
        let dialogData: DialogDataModel = {
            onClose: () => {
                popup.close();
                if (onClose) {
                    onClose(popup);
                }
            },
            header: {title},
            actionButtons: [
                {
                    label: cancelText || 'Cancel', command: () => {
                        popup.close();
                        if (onCancel) {
                            onCancel(popup);
                        }
                    },
                    actionType: ButtonActionTypes.CANCEL
                },
                {
                    label: confirmText || 'Confirm', btnType: ButtonTypes.FLAT, color: 'secondary', command: () => {
                        popup.close();
                        if (onConfirm) {
                            onConfirm(popup);
                        }
                    },
                    actionType: ButtonActionTypes.SUBMIT
                }
            ],
            bodyHtml: bodyText
        };
        const popup = this.dialog.open(DefaultDialogComponent, {
            width: width || DialogSizeEnum.SMALL,
            data: dialogData,
            autoFocus: false
        });

        popup.afterClosed().subscribe(() => {
            if (afterClosed) {
                afterClosed();
            }
        });
    }
}
