import {ApplicationConfig, provideZoneChangeDetection} from '@angular/core';
import {provideRouter} from '@angular/router';
import {routes} from './app.routes';
import {provideClientHydration} from '@angular/platform-browser';
import {provideAnimationsAsync} from '@angular/platform-browser/animations/async';
import {HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi} from "@angular/common/http";
import {TokenInterceptorService} from "./shared/_services/helpers/token-interceptor.service";
import {AuthenticationService} from "./shared/_services/apis/authentication.service";
import {AuthGuard} from "./shared/_guards/auth.guard";
import {ErrorInterceptorService} from "./shared/_services/helpers/error-interceptor.service";
import {DatePipe, DecimalPipe, CurrencyPipe} from "@angular/common";

export const appConfig: ApplicationConfig = {
    providers: [
        provideZoneChangeDetection({eventCoalescing: true}), provideRouter(routes),
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptorService,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ErrorInterceptorService,
            multi: true
        },
        provideHttpClient(
            withInterceptorsFromDi()
        ),
        provideClientHydration(),
        provideAnimationsAsync(),
        AuthenticationService,
        AuthGuard,
        DatePipe,
        DecimalPipe,
        CurrencyPipe
    ]
};
