import {AfterViewInit, Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {CommonModule} from "@angular/common";
import {NgxPopperjsModule} from "ngx-popperjs";
import {MessageComponent, MessageType} from "../message/message.component";
import {Subject} from "rxjs";
import {ButtonTypes} from "../../_model/button.model";
import {debounceTime, takeUntil} from "rxjs/operators";
import {MatDialogModule} from "@angular/material/dialog";
import {BaseComponent} from "../base/base.component";
import {ButtonActionTypes, DialogActionButton, DialogDataModel} from "../../_model/dialog.model";
import {MatMenuModule} from "@angular/material/menu";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {MatButtonModule} from "@angular/material/button";

@Component({
    selector: 'app-dialog-wrapper',
    standalone: true,
    imports: [
        CommonModule,
        NgxPopperjsModule,
        MessageComponent,
        MatDialogModule,
        MatMenuModule,
        MatProgressSpinnerModule,
        MatButtonModule
    ],
    templateUrl: './dialog-wrapper.component.html',
    styleUrl: './dialog-wrapper.component.scss'
})
export class DialogWrapperComponent extends BaseComponent implements AfterViewInit, OnDestroy {
    @Input() data: DialogDataModel;
    @Input() loading: boolean;
    @Input() disableButton: boolean;
    @Input() hideButton: boolean;
    @Input() actionStyleCls: 'center' | 'right' = 'right';
    @Output() onSubmit: EventEmitter<DialogActionButton> = new EventEmitter();
    @Input() errorMsgClass: string;

    // Error msg
    @Input() errorMsg: string;
    ZenMessageType = MessageType;
    ButtonTypes = ButtonTypes;
    ActionType = ButtonActionTypes;

    overlayWrapper = document.getElementsByClassName('cdk-global-overlay-wrapper');
    // Centering
    private onResizeSubject: Subject<void> = new Subject<void>();

    constructor() {
        super();
        this.onResizeSubject.pipe(debounceTime(250), takeUntil(this.destroy$))
            .subscribe(() => this.reAdjustDialogs());
    }

    ngAfterViewInit(): void {
        let index = 0; // default
        if (this.overlayWrapper.length > 0) {
            // sometime when there is more than one overlay component (e.g - snackbar and dialog)
            // we need to append the class to the last component.
            index = this.overlayWrapper.length - 1;
        }
        this.overlayWrapper[index].className += ' zen-dialog-wrapper ';

        // Trigger resize listener for perfect centering
        this.reAdjustDialogs();

        // When dialog size changes, trigger resize listener
        const observer = new ResizeObserver(() => this.reAdjustDialogs());
        observer.observe(document.querySelector('.dialog-wrapper-main'));
    }

    @HostListener('window:resize', ['$event'])
    onResize() {
        this.onResizeSubject.next();
    }

    /**
     * If any dialogs are open, adjust their height to be centered yet scrollable
     */
    private reAdjustDialogs() {
        let openDialogContainers = document.getElementsByClassName('zen-dialog-wrapper');
        for (let i = 0; i < openDialogContainers.length; i++) {
            // Get the dialog itself
            let dialog = openDialogContainers[i].firstChild;
            // Get the dialog height
            // @ts-ignore
            let dialogHeight = dialog.scrollHeight;
            // If the height is greater than the window height, set top to 0 and scroll. Otherwise, null
            if (dialogHeight > window.innerHeight) {
                // @ts-ignore
                dialog.style.top = '0';
            } else {
                // @ts-ignore
                dialog.style.top = null;
            }
        }
    }
}
