import {Component, OnInit} from '@angular/core';
import {MatIconModule} from "@angular/material/icon";
import {MatButtonModule} from "@angular/material/button";
import {MatToolbarModule} from "@angular/material/toolbar";
import {MatSidenavModule} from "@angular/material/sidenav";
import {RouterModule} from "@angular/router";
import {MatListModule} from "@angular/material/list";
import {FlexLayoutModule} from "ngx-flexible-layout";
import {FlexLayoutServerModule} from "ngx-flexible-layout/server";
import {AuthenticationService} from "../../shared/_services/apis/authentication.service";
import {CommonModule} from "@angular/common";
import {NavigationService} from "../../shared/_services/helpers/navigation.service";
import {MatMenuModule} from "@angular/material/menu";
import {BreadcrumbComponent} from "../breadcrump/breadcrumb.component";
import {BreadcrumbService} from "../breadcrump/breadcrumb.service";
import {MarketplaceAuthResponse} from "../../shared/_model/marketplace-auth-request.model";
import {getSupplierImagePath} from "../../shared/_utils/supplier.util";

@Component({
    selector: 'app-layout',
    templateUrl: './layout.component.html',
    standalone: true,
    imports: [
        CommonModule,
        MatIconModule,
        MatButtonModule,
        MatToolbarModule,
        MatSidenavModule,
        RouterModule,
        MatListModule,
        FlexLayoutModule,
        FlexLayoutServerModule,
        MatMenuModule,
        BreadcrumbComponent
    ],
    styleUrl: './layout.component.scss'
})
export class LayoutComponent implements OnInit {
    menuOptions: { label: string; link: string[] }[] = [];
    currentUser: MarketplaceAuthResponse;
    supplierLogoPath: string;

    constructor(
        public authSvc: AuthenticationService,
        public navSvc: NavigationService,
        public bcSvc: BreadcrumbService
    ) {
        this.menuOptions = [
            {label: 'Dashboard', link: this.navSvc.getDashboard()},
            {label: 'Deal Desk', link: this.navSvc.getDealDeskRoute()},
            {label: 'Booked Contracts', link: this.navSvc.getBookedContracts()},
            {label: 'Bid History', link: this.navSvc.getBidHistory()}
        ];
    }

    ngOnInit() {
        this.currentUser = this.authSvc.getCurrentUser;
        this.supplierLogoPath = getSupplierImagePath(this.currentUser?.supplierRep?.supplierId)
    }
}
