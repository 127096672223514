<div class="layout-main">
    <mat-toolbar color="primary">
        <mat-toolbar-row>
            <button mat-icon-button (click)="sidenav.toggle()" fxShow="true" fxHide.gt-md>
                <mat-icon>menu</mat-icon>
            </button>
            <span class="ms-4 me-3 me-sm-3 me-md-5">
                <a href="#" target="_blank">
                    <img src="./images/zentility-mp-logo.svg" alt="Zentility"/>
                </a>
            </span>

            <div fxShow="true" fxHide.lt-lg>
                <!-- The following menu items will be hidden on both SM and XS screen sizes -->
                <ng-container *ngFor="let op of menuOptions">
                    <a mat-button class="text-md text-color mx-2" [routerLink]="op.link" routerLinkActive="router-link-active">{{op.label}}</a>
                </ng-container>
            </div>

            <span class="menu-spacer"></span>

            <div fxHide.lt-sm>
                <img *ngIf="supplierLogoPath else supplierNameTpl" class="supplier-logo" [src]="supplierLogoPath">
                <ng-template #supplierNameTpl>
                    <p class="me-4">{{currentUser?.supplierRep?.supplierName}}</p>
                </ng-template>
            </div>

            <!-- Future enhancement -->
            <!-- <mat-icon class="text-color-light">settings</mat-icon>-->
            <button mat-mini-fab class="user-menu-btn" [matMenuTriggerFor]="settingsMenu">
                <span>{{currentUser?.supplierRep?.firstName?.charAt(0)}}</span>
            </button>

            <mat-menu #settingsMenu>
                <a mat-menu-item>
                    <span class="font-weight-bold">{{currentUser?.supplierRep?.firstName}} {{currentUser?.supplierRep?.lastName}}</span>
                </a>
                <a mat-menu-item (click)="authSvc.logout()">Logout</a>
            </mat-menu>

        </mat-toolbar-row>
    </mat-toolbar>

    <mat-sidenav-container fxFlexFill>
        <mat-sidenav #sidenav>
            <mat-nav-list>
                <a (click)="sidenav.toggle()" href="" mat-list-item>Close</a>
                <ng-template [ngTemplateOutlet]="allItems"></ng-template>
            </mat-nav-list>
        </mat-sidenav>
        <mat-sidenav-content fxFlexFill>
            <div class="router-outlet-main">
                <div class="router-outlet-container" [class.bc-exist]="bcSvc.config">
                    <div class="m-3">
                        <div class="mb-5" *ngIf="bcSvc.config">
                            <app-breadcrumb></app-breadcrumb>
                        </div>
                        <router-outlet/>
                    </div>
                </div>
            </div>
        </mat-sidenav-content>
    </mat-sidenav-container>
</div>

<ng-template #allItems>
    <a href="#" mat-list-item>Menu Item 1</a>
    <a href="#" mat-list-item>Menu Item 2</a>
    <a href="#" mat-list-item>Menu Item 3</a>
    <a href="#" mat-list-item>Menu Item 4</a>
    <a href="#" mat-list-item>Menu Item 5</a>
    <a mat-list-item (click)="authSvc.logout()">Logout</a>
</ng-template>
