import {Component, Input, OnInit} from '@angular/core';
import {CommonModule} from "@angular/common";
import {ZenIconsEnum} from "../../_enums/zen-icons.enum";
import {DomSanitizer, SafeHtml} from "@angular/platform-browser";

export enum MessageType {
  INFO = 'info', ERROR = 'error', SUCCESS = 'success'
}

@Component({
  selector: 'app-message',
  standalone: true,
  imports: [
      CommonModule
  ],
  templateUrl: './message.component.html',
  styleUrl: './message.component.scss'
})
export class MessageComponent implements OnInit {
  @Input() type = MessageType.INFO;
  @Input() message: string;
  @Input() icon: ZenIconsEnum = ZenIconsEnum.INFO;
  safeHtmlMsg: SafeHtml;

  constructor(private sanitizer: DomSanitizer) {
  }

  ngOnInit(): void {
    if (this.message) {
      this.safeHtmlMsg = this.sanitizer.bypassSecurityTrustHtml(this.message);
    }
  }

}
